
import { defineComponent } from "vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import sessionApp from "@/libs/session";
import cartApp from "@/libs/cart";
import wishlistApp from "@/libs/wishlist";
import notificationApp from "@/libs/notification";
import { event as gtagEvent } from "vue-gtag";

export default defineComponent({
  props: ["channel"],
  setup(props) {
    const { t, te } = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const storeX = useStore();
    const { createLoading, openToast, registerDevice } = useGlobalFunctions();
    const { getLocalStorageData, setLocalStorageData } = useLocalStorage();

    const validateAuth = async () => {
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      const channel = props.channel;
      const domainLangUrl =
        window.location.origin + "/" + storeX.state.storeCode;
      const callbackUrl = domainLangUrl + "/account/socialconnect/" + channel;
      const urlencoded = new URLSearchParams();
      urlencoded.append("code", route.query["code"] as string);
      urlencoded.append("state", route.query["state"] as string);
      urlencoded.append("callback_url", callbackUrl as string);
      let socialConnectUrl;
      if (channel == "facebook") {
        socialConnectUrl = settingsApp.getEndpointUrl("facebookConnect");
      } else if (channel == "google") {
        socialConnectUrl = settingsApp.getEndpointUrl("googleConnect");
      } else if (channel == "apple") {
        socialConnectUrl = settingsApp.getEndpointUrl("appleConnect");
      }
      httpApp
        .sendPostRequest(socialConnectUrl, urlencoded, false)
        .then((resp: any) => {
          loading.dismiss();
          if (resp.success) {
            if (channel == "facebook") {
              gtagEvent("login", { method: "Facebook" });
            } else if (channel == "google") {
              gtagEvent("login", { method: "Google" });
            } else if (channel == "apple") {
              gtagEvent("login", { method: "Apple" });
            }
            if (resp.loggedin) {
              const getCustomerDataPromise = sessionApp.getCustomerData();
              getCustomerDataPromise
                .then((customerDataResp: any) => {
                  loading.dismiss();
                  storeX.commit("setCustomerData", customerDataResp);
                  registerDevice();
                  settingsApp.getVendorData();
                  if (resp.new_registered) {
                    router.replace(
                      "/" + storeX.state.storeCode + "/account/account-details"
                    );
                  } else {
                    const referralUrl = getLocalStorageData("referralUrl");
                    if (referralUrl) {
                      router.replace(referralUrl);
                      setLocalStorageData({ referralUrl: null });
                    } else {
                      router.replace("/" + storeX.state.storeCode + "/account");
                    }
                  }
                })
                .catch(() => {
                  router.replace("/" + storeX.state.storeCode + "/account");
                });
              cartApp.getQuoteItems();
              wishlistApp.getWishlistItems();
              notificationApp.getNotifications(1);
              notificationApp.getNewNotificationsCount(false);
            } else {
              router.replace("/" + storeX.state.storeCode + "/account");
            }
          } else {
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
            } else {
              openToast(resp.error.message, "danger");
            }
            router.replace("/" + storeX.state.storeCode + "/account");
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    onIonViewWillEnter(() => {
      validateAuth();
    });

    return {
      t,
    };
  },
  components: {
    IonPage,
    IonContent,
  },
});
